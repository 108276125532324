/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck
import React, { useState, useRef, useEffect, useCallback } from 'react';
import { Button } from '@/components/ui/button';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/dialog';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import {
  Sheet,
  SheetContent,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
  SheetClose,
} from '@/components/ui/sheet';
import { Checkbox } from '@/components/ui/checkbox';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import {
  ChevronLeft,
  ChevronRight,
  Filter,
  Plus,
  Settings,
  Edit,
  Copy,
  BarChart,
  Trash,
  X,
  Calendar,
  MessageSquare,
  Bell,
  Mail,
} from 'lucide-react';
import { observer } from 'mobx-react-lite';
import { cn } from '@/lib/utils';
import { replaceRouteParam } from '@/utils/routes';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/components/ui/popover';
import { Calendar as CalendarComponent } from '@/components/ui/calendar';
import { RadioGroup, RadioGroupItem } from '@/components/ui/radio-group';
import { useEmailMarketing } from '@/hooks/useEmailMarketing';
import { SelectSingleEventHandler } from 'react-day-picker';
import {
  EmailCampaignFilter,
  emailMarketingStore,
} from '@/state/EmailMarketingStore';
import { useList } from '@/hooks/useList';
import { notify } from '@/helpers';
import { listStore } from '@/state/ListStore';
import { format } from 'date-fns';
import routeNames from '@/routes/routeNames';
import { AuthStore } from '@/state/AuthenticationStore';
import { useNavigate } from 'react-router-dom';
import ConfirmationModal from '@/components/atoms/modal/ConfirmationModal';

interface CampaignDetails {
  goals: string[];
  title: string;
  templateId: string;
  listId: string;
  type: string;
  draftDate: Date;
  status: EmailCampaignFilter;
}

const CampaignCalendar = () => {
  const navigate = useNavigate();
  const [currentDate, setCurrentDate] = useState(new Date());
  const [selectedDate, setSelectedDate] = useState<string | null>(null);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isOpenConfirmationModal, setIsOpenConfirmationModal] = useState(false);
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [currentView, setCurrentView] = useState<'month' | 'week'>('month');
  const [startHour, setStartHour] = useState(8);
  const weekViewRef = useRef<HTMLDivElement>(null);
  const [campaignType, setCampaignType] = useState('');
  const [isEditing, setIsEditing] = useState(false);
  const [draftDate, setDraftDate] = useState<Date>();
  const [goals, setGoals] = useState<string[]>([]);
  const [goal, setGoal] = useState('');
  const [details, setDetails] = useState<CampaignDetails>({
    goals: [],
    title: '',
    templateId: '',
    listId: '',
    type: '',
    draftDate: '',
    status: EmailCampaignFilter.DRAFT,
  });
  const { getLists } = useList();
  const {
    getTemplates,
    createCampaign,
    getCampaigns,
    getEmailCampaignStatusStats,
    updateCampaign,
    getCampaign,
    deleteCampaign,
    // saveAndSendCampaign,
  } = useEmailMarketing();

  const campaigns = emailMarketingStore?.campaigns;

  const daysInMonth = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth() + 1,
    0,
  ).getDate();
  const firstDayOfMonth = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    1,
  ).getDay();

  const prevPeriod = () => {
    if (currentView === 'month') {
      setCurrentDate(
        new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 1),
      );
    } else {
      setCurrentDate(
        new Date(
          currentDate.getFullYear(),
          currentDate.getMonth(),
          currentDate.getDate() - 7,
        ),
      );
    }
  };

  const nextPeriod = () => {
    if (currentView === 'month') {
      setCurrentDate(
        new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 1),
      );
    } else {
      setCurrentDate(
        new Date(
          currentDate.getFullYear(),
          currentDate.getMonth(),
          currentDate.getDate() + 7,
        ),
      );
    }
  };

  const goToToday = () => {
    setCurrentDate(new Date());
  };

  const handleDateClick = (day: number) => {
    const date = `${currentDate.getFullYear()}-${String(
      currentDate.getMonth() + 1,
    ).padStart(2, '0')}-${String(day).padStart(2, '0')}`;
    setSelectedDate(date);
    openModal(date);
  };

  const openModal = (date?: string) => {
    const draftDateValue = date ? new Date(date) : new Date();
    setDetails(prevDetails => ({
      ...prevDetails,
      goals: [],
      title: '',
      templateId: '',
      listId: '',
      type: campaignType,
      draftDate: draftDateValue,
      status: EmailCampaignFilter.DRAFT,
    }));
    setCampaignType('');
    setDraftDate(draftDateValue);
    setGoals([]);
    setIsOpenModal(true);
  };

  const openTypeModal = (campaignType?: string) => {
    setIsEditing(false);
    setDetails(prevDetails => ({
      ...prevDetails,
      goals: [],
      title: '',
      templateId: '',
      listId: '',
      type: campaignType,
      draftDate: '',
      status: EmailCampaignFilter.DRAFT,
    }));
    setCampaignType(campaignType);
    setDraftDate(new Date());
    setGoals([]);
    setIsOpenModal(true);
  };

  const listOptions = [
    { label: 'All Contacts', value: 'all' },
    { label: 'My Contacts', value: 'my_contacts' },
    ...(listStore.lists?.map(list => ({
      label: list.name,
      value: list._id,
    })) || []),
  ];

  const templates =
    emailMarketingStore.templates?.map(temp => ({
      label: temp.name,
      value: temp._id,
    })) || [];

  const handleChange = useCallback((e: any) => {
    const { name, value } = e.target;
    setDetails((prevDetails: any) => ({
      ...prevDetails,
      [name]: value.trimStart(),
    }));
  }, []);

  const handleUpdateClick = (selectedCampaign: string) => {
    getCampaign(selectedCampaign);
    setIsEditing(true);
    setIsOpenModal(true);
  };

  const handleDateChange: SelectSingleEventHandler = (date: any) => {
    const selectedDate = new Date(date);
    const formattedDate = `${selectedDate.getFullYear()}-${String(
      selectedDate.getMonth() + 1,
    ).padStart(2, '0')}-${String(selectedDate.getDate()).padStart(2, '0')}`;
    setDraftDate(formattedDate);
    setDetails(prevDetails => ({
      ...prevDetails,
      draftDate: formattedDate,
    }));
  };

  const handleCampaignTypeChange = (type: string) => {
    setCampaignType(type);
    setDetails(prevDetails => ({
      ...prevDetails,
      type,
    }));
  };
  const handlSave = () => {
    createCampaign(details);
    setIsOpenModal(false);
  };

  const handleUpdate = () => {
    updateCampaign({
      ...details,
      id: emailMarketingStore?.campaign?._id as string,
    });
    setIsOpenModal(false);
  };

  const handleKeyUp = (e: any) => {
    if (e.key === 'Enter' && goal.trim() !== '') {
      const goalParts = goal
        .split(',')
        .map(g => g.trim())
        .filter(g => g !== '');
      setGoals(prevGoals => [...prevGoals, ...goalParts]);
      setDetails((prevDetails: any) => ({
        ...prevDetails,
        goals: [...prevDetails.goals, ...goalParts],
      }));
      setGoal('');
    }
  };

  const handleGoalChange = (e: any) => {
    setGoal(e.target.value);
  };

  const handleRemove = (goalToRemove: string) => {
    setGoals(prevGoals => prevGoals.filter(g => g !== goalToRemove));
    setDetails((prevDetails: any) => ({
      ...prevDetails,
      goals: prevDetails.goals.filter((g: any) => g !== goalToRemove),
    }));
  };

  const handleSubmit = () => {
    if (
      !details?.title ||
      details?.goals?.length === 0 ||
      !details.listId ||
      !details.templateId
    ) {
      notify('error', 'Please fill all the details');
      return;
    }
    if (isEditing && emailMarketingStore?.campaign?._id) {
      handleUpdate();
    } else {
      handlSave();
    }
  };

  const templateBuilder = () => {
    navigate(
      replaceRouteParam(
        `${routeNames.dashboard.emailTemplates}`,
        'workspaceId',
        AuthStore.user_workspace_info.active_workspace.workspace._id,
      ),
    );
  };
  const audience = () => {
    navigate(
      replaceRouteParam(
        `${routeNames.dashboard.contacts}/`,
        'workspaceId',
        AuthStore.user_workspace_info.active_workspace.workspace._id,
      ),
    );
  };

  useEffect(() => {
    getLists();
    getTemplates();
  }, []);

  useEffect(() => {
    if (isEditing && emailMarketingStore?.campaign) {
      const campaignData = emailMarketingStore.campaign;
      setDetails({
        goals: campaignData?.goals || [],
        title: campaignData?.title || '',
        templateId: campaignData?.template || '',
        listId: campaignData?.listId || '',
        status: campaignData?.status || '',
        type: campaignData?.type || '',
        draftDate: campaignData?.draftDate || '',
      });
      setCampaignType(campaignData?.type || '');
      setDraftDate(
        campaignData?.draftDate
          ? new Date(campaignData.draftDate)
          : campaignData.draftDate,
      );

      setGoals(campaignData?.goals || []);
    }
  }, [emailMarketingStore?.campaign, isEditing]);

  useEffect(() => {
    getCampaigns({
      filter: EmailCampaignFilter.ALL,
    });
    getEmailCampaignStatusStats();
  }, [EmailCampaignFilter.ALL]);

  const renderMonthCampaigns = (day: number) => {
    const date = `${currentDate.getFullYear()}-${String(
      currentDate.getMonth() + 1,
    ).padStart(2, '0')}-${String(day).padStart(2, '0')}`;
    const dayCampaigns = campaigns.filter(
      campaign => campaign?.draftDate?.split('T')[0] === date,
    );
    return (
      <>
        {dayCampaigns?.map(campaign => (
          <div
            key={campaign?._id}
            className='text-xs p-1 bg-secondary/20 rounded mb-1 truncate cursor-pointer 
            hover:bg-secondary/30 transition-colors duration-200 group relative'
          >
            {campaign?.title}
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <Button
                  variant='ghost'
                  size='icon'
                  className='h-4 w-4 absolute right-1 top-1/2 transform -translate-y-1/2 opacity-0 group-hover:opacity-100 transition-opacity duration-200'
                >
                  <Settings className='h-3 w-3' />
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent align='end'>
                <DropdownMenuItem
                  onClick={() => handleUpdateClick(campaign._id)}
                >
                  <Edit className='mr-2 h-4 w-4' />
                  Edit
                </DropdownMenuItem>
                <DropdownMenuItem>
                  <Copy className='mr-2 h-4 w-4' />
                  Clone
                </DropdownMenuItem>
                <DropdownMenuItem>
                  <BarChart className='mr-2 h-4 w-4' />
                  Analytics
                </DropdownMenuItem>
                <DropdownMenuItem
                  onClick={e => {
                    e.stopPropagation();
                    emailMarketingStore.setCampaign(campaign);
                    setIsOpenConfirmationModal(true);
                  }}
                >
                  <Trash className='mr-2 h-4 w-4' />
                  Delete
                </DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu>
          </div>
        ))}
        <div
          className='text-xs p-1 text-gray-400 cursor-pointer hover:text-gray-600 transition-colors duration-200'
          onClick={() => handleDateClick(day)}
        >
          + New Campaign
        </div>
      </>
    );
  };

  const moveDay = (direction: 'left' | 'right') => {
    const newDate = new Date(currentDate);
    newDate.setDate(newDate.getDate() + (direction === 'left' ? -1 : 1));
    setCurrentDate(newDate);
  };

  const renderWeekView = () => {
    const weekStart = new Date(currentDate);
    weekStart.setDate(currentDate.getDate() - currentDate.getDay());
    const weekDays = Array.from({ length: 7 }, (_, i) => {
      const day = new Date(weekStart);
      day.setDate(weekStart.getDate() + i);
      return day;
    });

    const timeSlots = Array.from({ length: 24 }, (_, i) => {
      const hour = (startHour + i) % 24;
      return `${hour % 12 || 12}:00 ${hour < 12 ? 'AM' : 'PM'}`;
    });

    return (
      <div className='grid grid-cols-8 gap-x-2' ref={weekViewRef}>
        <div className='col-span-1'></div>
        {weekDays.map((day, index) => (
          <div key={index} className='text-center font-semibold p-2 relative'>
            {index === 0 && (
              <Button
                variant='ghost'
                size='icon'
                className='absolute left-0 top-1/2 transform -translate-y-1/2'
                onClick={() => moveDay('left')}
              >
                <ChevronLeft className='h-4 w-4' />
              </Button>
            )}
            {day.toLocaleDateString('en-US', { weekday: 'short' })}
            <br />
            {day.getDate()}
            {index === 6 && (
              <Button
                variant='ghost'
                size='icon'
                className='absolute right-0 top-1/2 transform -translate-y-1/2'
                onClick={() => moveDay('right')}
              >
                <ChevronRight className='h-4 w-4' />
              </Button>
            )}
          </div>
        ))}
        {timeSlots.map((time, timeIndex) => (
          <React.Fragment key={time}>
            <div className='text-right pr-2 text-sm'>{time}</div>
            {weekDays.map((day, dayIndex) => {
              const date = `${day.getFullYear()}-${String(
                day.getMonth() + 1,
              ).padStart(2, '0')}-${String(day.getDate()).padStart(2, '0')}`;
              const dayCampaigns = campaigns.filter(
                campaign =>
                  campaign?.draftDate?.split('T')[0] === date &&
                  parseInt(
                    campaign?.draftDate
                      ?.split('T')[1]
                      .slice(0, 5)
                      .split(':')[0],
                  ) ===
                    (startHour + timeIndex) % 24,
              );
              return (
                <div
                  key={`${dayIndex}-${timeIndex}`}
                  className='border-t border-l border-r p-1 h-12 overflow-hidden'
                >
                  {dayCampaigns.map(campaign => (
                    <div
                      key={campaign._id}
                      className='text-xs p-1 bg-secondary/20 rounded mb-1 truncate cursor-pointer hover:bg-secondary/30 transition-colors duration-200'
                      onClick={() => handleUpdateClick(campaign._id)}
                    >
                      {campaign.title}
                    </div>
                  ))}
                </div>
              );
            })}
          </React.Fragment>
        ))}
      </div>
    );
  };

  useEffect(() => {
    const handleWheel = (e: WheelEvent) => {
      if (currentView === 'week') {
        e.preventDefault();
        setStartHour(prevStartHour => {
          const newStartHour = prevStartHour + (e.deltaY > 0 ? 1 : -1);
          return Math.max(0, Math.min(23, newStartHour));
        });
      }
    };

    const weekViewElement = weekViewRef.current;
    if (weekViewElement) {
      weekViewElement.addEventListener('wheel', handleWheel, {
        passive: false,
      });
    }

    return () => {
      if (weekViewElement) {
        weekViewElement.removeEventListener('wheel', handleWheel);
      }
    };
  }, [currentView]);

  return (
    <div className='bg-white p-6 rounded-lg shadow-sm max-w-6xl mx-auto  mb-20 mt-10'>
      <h1 className='text-2xl font-bold mb-4'>Campaign Calendar</h1>
      <div className='flex justify-between items-center mb-4'>
        <div className='flex items-center space-x-4'>
          <Button
            onClick={goToToday}
            className='bg-secondary text-white hover:bg-secondary/80'
          >
            Today
          </Button>
          <Button variant='outline' onClick={prevPeriod}>
            <ChevronLeft className='h-4 w-4' />
          </Button>
          <Button variant='outline' onClick={nextPeriod}>
            <ChevronRight className='h-4 w-4' />
          </Button>
          <span className='text-lg font-semibold'>
            {currentDate.toLocaleString('default', {
              month: 'long',
              year: 'numeric',
            })}
          </span>
        </div>
        <div className='flex items-center space-x-4'>
          <Sheet open={isFilterOpen} onOpenChange={setIsFilterOpen}>
            <SheetTrigger asChild>
              <Button variant='outline'>
                <Filter className='h-4 w-4 mr-2' />
                Filters
              </Button>
            </SheetTrigger>
            <SheetContent>
              <SheetHeader>
                <SheetTitle>Filters</SheetTitle>
                <SheetClose asChild>
                  <Button variant='ghost' size='icon'>
                    <X className='h-4 w-4' />
                  </Button>
                </SheetClose>
              </SheetHeader>
              <div className='py-4 space-y-6'>
                <div>
                  <h3 className='mb-2 font-semibold'>Type</h3>
                  <div className='space-y-2'>
                    {['Email', 'SMS', 'Push', 'A/B Test Only'].map(type => (
                      <div key={type} className='flex items-center'>
                        <Checkbox id={type.toLowerCase()} />
                        <label htmlFor={type.toLowerCase()} className='ml-2'>
                          {type}
                        </label>
                      </div>
                    ))}
                  </div>
                </div>
                <div>
                  <h3 className='mb-2 font-semibold'>Status</h3>
                  <div className='space-y-2'>
                    {['Sent', 'Scheduled', 'Draft / Paused', 'Canceled'].map(
                      status => (
                        <div key={status} className='flex items-center'>
                          <Checkbox
                            id={status.toLowerCase().replace(' ', '-')}
                          />
                          <label
                            htmlFor={status.toLowerCase().replace(' ', '-')}
                            className='ml-2'
                          >
                            {status}
                          </label>
                        </div>
                      ),
                    )}
                  </div>
                </div>
                <div>
                  <h3 className='mb-2 font-semibold'>Tags</h3>
                  <Select>
                    <SelectTrigger className='focus:ring-0 focus:ring-offset-0 focus-within:border focus-within:border-secondary'>
                      <SelectValue placeholder='Select tags' />
                    </SelectTrigger>
                    <SelectContent>
                      <SelectItem value='tag1'>Tag 1</SelectItem>
                      <SelectItem value='tag2'>Tag 2</SelectItem>
                      <SelectItem value='tag3'>Tag 3</SelectItem>
                    </SelectContent>
                  </Select>
                </div>
                <div>
                  <h3 className='mb-2  font-semibold'>List</h3>
                  <Select>
                    <SelectTrigger className='focus:ring-0 focus:ring-offset-0 focus-within:border focus-within:border-secondary'>
                      <SelectValue placeholder='Select one or more options' />
                    </SelectTrigger>
                    <SelectContent>
                      <SelectItem value='list1'>List 1</SelectItem>
                      <SelectItem value='list2'>List 2</SelectItem>
                      <SelectItem value='list3'>List 3</SelectItem>
                    </SelectContent>
                  </Select>
                </div>
                <div className='flex justify-between'>
                  <Button className='bg-secondary text-white hover:bg-secondary/80'>
                    Apply
                  </Button>
                  <Button variant='outline'>Clear</Button>
                </div>
              </div>
            </SheetContent>
          </Sheet>
          <Select
            value={currentView}
            onValueChange={value => setCurrentView(value as 'month' | 'week')}
          >
            <SelectTrigger className='w-[180px] focus:ring-0 focus:ring-offset-0 focus-within:border focus-within:border-secondary'>
              <SelectValue placeholder='Select view' />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value='week'>Week</SelectItem>
              <SelectItem value='month'>Month</SelectItem>
            </SelectContent>
          </Select>
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button className='bg-secondary text-white hover:bg-secondary/80'>
                <Plus className='h-4 w-4 mr-2' />
                New Campaign
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent>
              <DropdownMenuItem
                className='text-md'
                onSelect={() => {
                  setDetails({ ...details });
                  openTypeModal('EMAIL');
                }}
              >
                Email Campaign
              </DropdownMenuItem>
              <DropdownMenuItem
                className='text-md'
                onSelect={() => {
                  setDetails({ ...details });
                  openTypeModal('SMS');
                }}
              >
                SMS Campaign
              </DropdownMenuItem>
              <DropdownMenuItem
                className='text-md'
                onSelect={() => {
                  setDetails({ ...details });
                  openTypeModal('PUSH');
                }}
              >
                Push Campaign
              </DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
        </div>
      </div>
      {currentView === 'month' ? (
        <div className='grid grid-cols-7 gap-2'>
          {['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'].map(day => (
            <div key={day} className='text-center font-semibold p-2'>
              {day}
            </div>
          ))}
          {Array.from({ length: firstDayOfMonth }).map((_, index) => (
            <div
              key={`empty-${index}`}
              className='p-2 border rounded-md bg-gray-50/50 h-32'
            ></div>
          ))}
          {Array.from({ length: daysInMonth }).map((_, index) => (
            <div
              key={index}
              className='p-2 border rounded-md h-32 overflow-y-auto relative cursor-pointer hover:bg-gray-50/50 transition-colors duration-200'
            >
              <div className='font-semibold mb-1'>{index + 1}</div>
              {renderMonthCampaigns(index + 1)}
            </div>
          ))}
        </div>
      ) : (
        renderWeekView()
      )}
      <Dialog open={isOpenModal} onOpenChange={setIsOpenModal}>
        <DialogContent className='bg-white border-none p-8 w-full max-w-xl max-h-[90vh] overflow-y-auto [&>button]:hidden'>
          <DialogHeader className='flex flex-row justify-between items-center'>
            <DialogTitle className='text-2xl font-semibold text-secondary'>
              Create
            </DialogTitle>
            <DialogClose asChild>
              <Button variant='ghost' size='icon'>
                <X className='h-6 w-6' />
              </Button>
            </DialogClose>
          </DialogHeader>
          <form onSubmit={handleSubmit} className='grid grid-cols-1 gap-4'>
            <div>
              <Label htmlFor='campaignName'>Campaign Name</Label>
              <Input
                id='campaignName'
                name='title'
                placeholder="Enter campaign name (e.g., 'Holiday Promo 2024')"
                value={details.title}
                onChange={handleChange}
                className='focus-visible:outline-none focus-visible:ring-0 focus-visible:ring-ring focus-visible:ring-offset-0 focus-within:border focus-within:border-secondary'
                required
              />
            </div>
            <div>
              <Label htmlFor='draftDate'>Draft Date</Label>
              <Popover>
                <PopoverTrigger asChild>
                  <Button
                    variant='outline'
                    className={cn(
                      'w-full justify-start text-left font-normal',
                      !draftDate && 'text-muted-foreground',
                    )}
                  >
                    <Calendar className='mr-2 h-4 w-4' />
                    {draftDate ? format(draftDate, 'PPP') : 'Select a date'}
                  </Button>
                </PopoverTrigger>
                <PopoverContent className='w-auto p-0'>
                  <CalendarComponent
                    timeZone='+02:00'
                    mode='single'
                    selected={draftDate}
                    onSelect={date => handleDateChange(date)}
                    initialFocus
                  />
                </PopoverContent>
              </Popover>
            </div>
            <div className='space-y-2'>
              <Label>Type</Label>
              <RadioGroup
                onValueChange={value => handleCampaignTypeChange(value)}
                value={campaignType}
                className='space-y-2'
              >
                {['EMAIL', 'SMS', 'PUSH'].map(type => (
                  <div
                    key={type}
                    className={`flex items-center space-x-2 rounded-md border p-4 transition-colors ${
                      campaignType === type
                        ? '!bg-secondary text-white'
                        : 'hover:bg-secondary/10'
                    }`}
                  >
                    <RadioGroupItem value={type} id={type} />
                    <Label
                      htmlFor={type}
                      className='flex items-center cursor-pointer'
                    >
                      {type === 'EMAIL' && <Mail className='mr-2 h-4 w-4' />}
                      {type === 'SMS' && (
                        <MessageSquare className='mr-2 h-4 w-4' />
                      )}
                      {type === 'PUSH' && <Bell className='mr-2 h-4 w-4' />}
                      {type.charAt(0).toUpperCase() + type.slice(1)}
                    </Label>
                  </div>
                ))}
              </RadioGroup>
              <p className='text-md text-muted-foreground mt-1'>
                Push messages will go out from your Sticky Card.
              </p>
            </div>
            <div>
              <Label htmlFor='emailTemplate'>Email Template</Label>
              <Select
                name='templateId'
                value={details.templateId}
                onValueChange={value =>
                  setDetails({ ...details, templateId: value })
                }
                required
              >
                <SelectTrigger className='focus:ring-0 focus:ring-offset-0 focus-within:border focus-within:border-secondary'>
                  <SelectValue placeholder='Select an email template' />
                </SelectTrigger>
                <SelectContent>
                  <span
                    className='cursor-pointer flex w-full items-center rounded-sm py-1.5 pl-8 pr-2 text-md  focus:text-accent-foreground data-[disabled]:pointer-events-none data-[disabled]:opacity-50'
                    onClick={templateBuilder}
                  >
                    + New Template
                  </span>
                  {templates?.map(template => (
                    <SelectItem key={template.value} value={template.value}>
                      {template.label}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
              <p className='text-md text-muted-foreground mt-1'>
                Choose an email template. Click Preview to see the design.
              </p>
            </div>
            <div>
              <Label htmlFor='recipients'>Recipients</Label>
              <Select
                name='listId'
                value={details.listId}
                onValueChange={value =>
                  setDetails({ ...details, listId: value })
                }
                required
              >
                <SelectTrigger className='focus:ring-0 focus:ring-offset-0 focus-within:border focus-within:border-secondary'>
                  <SelectValue placeholder='Select a recipient list' />
                </SelectTrigger>
                <SelectContent>
                  <span
                    className='cursor-pointer flex w-full items-center rounded-sm py-1.5 pl-8 pr-2 text-md outline-none focus:bg-accent focus:text-accent-foreground data-[disabled]:pointer-events-none data-[disabled]:opacity-50'
                    onClick={audience}
                  >
                    + New Audience
                  </span>
                  {listOptions?.map(list => (
                    <SelectItem key={list.value} value={list.value}>
                      {list.label}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
              <p className='text-md text-muted-foreground mt-1'>
                Select or create a recipient list for this campaign.
              </p>
            </div>
            <div>
              <Label htmlFor='goals'>Tags</Label>
              <Input
                id='goals'
                name='goals'
                value={goal}
                onChange={handleGoalChange}
                onKeyUp={handleKeyUp}
                onKeyDown={e => {
                  if (e.key === 'Enter' && !e.shiftKey) {
                    e.preventDefault();
                  }
                }}
                placeholder='Select or add tags (e.g., Holiday, Promotion)'
                className='focus-visible:outline-none focus-visible:ring-0 focus-visible:ring-ring focus-visible:ring-offset-0 focus-within:border focus-within:border-secondary'
              />
              <div className='flex flex-wrap gap-2 mt-2'>
                {goals.map((tag, index) => (
                  <span
                    key={index}
                    className='bg-primary/10 text-black px-2 py-1 rounded text-md'
                  >
                    {tag}
                    <button
                      type='button'
                      className='ml-2 text-primary'
                      onClick={() => handleRemove(tag)}
                    >
                      ×
                    </button>
                  </span>
                ))}
              </div>
              <p className='text-md text-muted-foreground mt-1'>
                Add tags to organize and track campaigns.
              </p>
            </div>
            <div className='flex justify-start space-x-2 mt-4'>
              <Button
                type='button'
                className='bg-secondary text-white'
                onClick={handleSubmit}
              >
                Create Campaign
              </Button>
            </div>
          </form>
        </DialogContent>
      </Dialog>
      <ConfirmationModal
        title='Delete Campaign'
        content='Are you sure you want to delete this campaign?'
        show={isOpenConfirmationModal}
        setShow={setIsOpenConfirmationModal}
        confirmText='Done'
        onConfirm={() => {
          deleteCampaign(emailMarketingStore?.campaign?._id as string);
          setIsOpenConfirmationModal(false);
        }}
      />
    </div>
  );
};
export default observer(CampaignCalendar);
