export const SOCKET_EVENT_NAMES = {
  // CHAT
  GET_CONVERSATIONS: 'get_conversations',
  GET_CONFIG: 'get_config',
  GET_MESSAGES: 'get_messages',
  UPDATE_USER_INFO: 'update_user_info',
  GET_USER_INFO: 'get_user_info',
  UPDATE_CONFIG: 'update_config',
  UPDATE_CON_TITLE: 'update_conversation_title',
  UPDATE_CON_STATUS: 'update_conversation_status',
  GET_UNREAD_CHANNEL_CONVERSATIONS_COUNTS:
    'get_unread_channel_conversations_count',

  // NOTE
  GET_LEAD_NOTES: 'get_lead_notes',
  CREATE_LEAD_NOTE: 'create_lead_note',
  DELETE_LEAD_NOTE: 'delete_lead_note',
  UPDATE_LEAD_NOTE: 'update_lead_note',

  // TASK
  GET_TASKS: 'get_tasks',
  CREATE_TASK: 'create_task',
  DELETE_TASK: 'delete_task',
  UPDATE_TASK: 'update_task',

  // STORAGE
  UPLOAD_FILE: 'upload',
  DELETE_FILE: 'delete_file',

  // USER AND TEAMMATE
  GET_TEAMMATES: 'get_team_mates',
  INVITE_TEAMMATES: 'invite_team_mates',
  GET_INVITES: 'get_invites',
  RESEND_INVITE: 'resend_invite_to_team_mates',
  DELETE_INVITE: 'delete_invite',
  DELETE_TEAMMATE: 'delete_team_mate',
  UPDATE_TEAMMATE_STATUS: 'update_teammate_status',
  UPDATE_USER_AVATAR: 'update_user_avatar',

  // CONTACT
  CREATE_RECENTLY_VIEWED_CONTACTS: 'create_recently_viewed_contacts',
  CREATE_MY_CONTACTS: 'create_my_contacts',
  ASSIGN_CONTACT: 'assign_contact',
  SAVE_TO_MY_CONTACT_LIST: 'assign_multiple_contact',
  SAVE_TO_LIST: 'save_contact_to_list',
  DELETE_CONTACT_FROM_LIST: 'delete_contact_from_list',
  BLOCK_UNBLOCK_CONTACT: 'block_unblock_contact',

  // TAG
  GET_TAGS: 'get_tags',
  CREATE_TAG: 'create_tag',
  DELETE_TAG: 'delete_tag',

  // WORKSPACE
  CREATE_WORKSPACE: 'create_workspace',
  UPDATE_WORKSPACE: 'update_workspace',
  SWITCH_WORKSPACE: 'change_active_workspace_data',
  GET_WORKSPACE_MESSAGES: 'get_workspace_messages',

  // EXTERNAL LINK
  CREATE_USER_EXTERNAL_LINK: 'create_user_external_link',
  GET_USER_EXTERNAL_LINKS: 'get_user_external_links',
  DELETE_USER_EXTERNAL_LINK: 'delete_user_external_link',
  UPDATE_USER_EXTERNAL_LINK: 'update_user_external_link',

  // CUSTOM FIELD
  CREATE_CUSTOM_FIELD: 'create_custom_field',
  GET_CUSTOM_FIELDS: 'get_custom_fields',
  DELETE_CUSTOM_FIELD: 'delete_custom_field',
  UPDATE_CUSTOM_FIELD: 'update_custom_field',
  UPDATE_CUSTOM_FIELD_VALUE: 'update_custom_field_value',
  GET_CUSTOM_FIELD_BY_CONTACT_ID: 'get_custom_field_by_contact_id',

  // EMAIL MARKETING
  GET_DOMAIN_RECORDS: 'get_domain_records',
  DISCONNECT_DOMAIN: 'disconnect_domain',
  GET_EMAIL_TEMPLATES: 'get_email_templates',
  GET_EMAIL_TEMPLATE: 'get_email_template',
  SAVE_EMAIL_TEMPLATE: 'save_email_template',
  DELETE_EMAIL_TEMPLATE: 'delete_email_template',
  CREATE_EMAIL_CAMPAIGN: 'create_email_campaign',
  GET_EMAIL_CAMPAIGNS: 'get_email_campaigns',
  GET_EMAIL_CAMPAIGN: 'get_email_campaign',
  UPDATE_EMAIL_CAMPAIGN: 'update_email_campaign',
  DELETE_EMAIL_CAMPAIGN: 'delete_email_campaign',
  VERIFY_DOMAIN: 'verify_domain',
  SEND_TEST_EMAIL: 'send_test_email',
  SAVE_AND_SEND_EMAIL_CAMPAIGN: 'save_and_send_email_campaign',
  GET_EMAIL_MARKETING_STATS: 'get_email_marketing_stats',
  GET_EMAIL_CAMPAIGN_STATUS_STATS: 'get_email_campaign_status_stats',
} as const;
