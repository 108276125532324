/* eslint-disable react/prop-types */
import Icon from '@/assets/Icons';
import Icons from '@/assets/Icons/icons.json';
import Checkbox from '@/components/atoms/Checkbox';
import Search from '@/components/atoms/Search';
import { User } from '@/types/user.types';
import { Menu, Transition } from '@headlessui/react';
import { AccessorKeyColumnDef, VisibilityState } from '@tanstack/react-table';
import { Fragment, useState } from 'react';
import { useLocation } from 'react-router-dom';

export type Field = {
  id: number;
  name: string;
  value: string;
  omit: boolean;
};

interface TableListFilterProps {
  handleOmitField: (fieldName: string) => void;
  columnVisibility: VisibilityState;
  columns: AccessorKeyColumnDef<User, string>[];
}

export const TableListFilter: React.FC<TableListFilterProps> = ({
  columns,
  columnVisibility,
  handleOmitField,
}) => {
  const location = useLocation();
  const [searchResults, setSearchResults] = useState<
    AccessorKeyColumnDef<User, string>[]
  >([]);

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const searchValue = e.target.value;
    const filteredFields = columns.filter(field =>
      field?.id?.toLowerCase().includes(searchValue.toLowerCase()),
    );

    setSearchResults(filteredFields);
  };

  return (
    <>
      <Menu as='div' className='relative'>
        {({ open }) => (
          <>
            <Menu.Button
              className={`border-border cursor-pointer w-[43px] px-3 p-2  rounded shadow border justify-center items-center gap-2 inline-flex`}
            >
              <Icon icon={Icons.grid} />
            </Menu.Button>

            <Transition
              show={open}
              as={Fragment}
              enter='transition ease-out duration-200'
              enterFrom='opacity-0 translate-y-1'
              enterTo='opacity-100 translate-y-0'
              leave='transition ease-in duration-150'
              leaveFrom='opacity-100 translate-y-0'
              leaveTo='opacity-0 translate-y-1'
            >
              <Menu.Items
                className={` ${
                  location.pathname === '/d/contacts/' ? '' : '-right-0'
                } absolute z-10 mt-2 w-56 origin-top-right
                  divide-y divide-gray-100 rounded-[4px] bg-white shadow-lg
                  ring-1 ring-black ring-opacity-5 focus:outline-none max-h-[55vh] overflow-y-auto small-scrollbar`}
              >
                <div className='p-2'>
                  <Search
                    placeholder='Search'
                    transparent
                    showCommand={false}
                    onChange={handleSearch}
                  />
                </div>

                <div className=' divide-y divide-border'>
                  {(searchResults?.length > 0 ? searchResults : columns)?.map(
                    item =>
                      item.meta?.label ? (
                        <div
                          key={item.meta?.label}
                          className={
                            'flex flex-row space-x-4 items-center cursor-pointer px-2 py-2'
                          }
                        >
                          <Checkbox
                            checked={columnVisibility[item.accessorKey]}
                            onChange={() => handleOmitField(item.accessorKey)}
                          />
                          <span className='block text-md pl-1'>
                            {item.meta?.label}
                          </span>
                        </div>
                      ) : null,
                  )}
                </div>
              </Menu.Items>
            </Transition>
          </>
        )}
      </Menu>
    </>
  );
};
