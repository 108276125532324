import RequireAuth from '@/components/organisms/RequireAuth';
import MainChatUi from '@/components/templates/MainChatUi';
import Layout from '@/layout/index';
import { AddWorkSpace } from '@/pages/AddWorkSpace';
import AgentProfile from '@/pages/AgentProfile';
import Configurations from '@/pages/Configurations';
import Contacts from '@/pages/Contacts';
import Download from '@/pages/Download';
import CampaignManager from '@/pages/EmailMarketing/Campaign';
import EmailTemplatesBuilder from '@/pages/EmailMarketing/EmailTemplatesBuilder';
import Analytics from '@/pages/EmailMarketing/Analytics';
import Setup from '@/pages/EmailMarketing/Setup';
import Templates from '@/pages/EmailMarketing/Templates';
import EmailVerifyPage from '@/pages/EmailVerifyPage';
import { ForgotPassword } from '@/pages/ForgotPassword';
import Home from '@/pages/Home';
import Inbox from '@/pages/Inbox';
import Integration from '@/pages/Integration';
import JoinTeam from '@/pages/JoinTeam';
import LandingPage from '@/pages/LandingPage';
import Login from '@/pages/LoginPage';
import Loyalty from '@/pages/Loyalty/Loyalty';
import CardType from '@/pages/Loyalty/Section/CardType';
import Design from '@/pages/Loyalty/Section/Design';
import Setting from '@/pages/Loyalty/Section/Setting';
import LoyaltyPWA from '@/pages/LoyaltyPWA';
import { NewPassword } from '@/pages/NewPassword';
import NoteFoundPage from '@/pages/NotFound';
import UserProfile from '@/pages/Profile';
import Settings from '@/pages/Settings';
import BlockedPeople from '@/pages/Settings/Sections/BlockedPeople';
import CustomFields from '@/pages/Settings/Sections/CustomFields';
import GeneralSettings from '@/pages/Settings/Sections/General';
import InvitePeople from '@/pages/Settings/Sections/Invites';
import OfficeHours from '@/pages/Settings/Sections/OfficeHours';
import Permissions from '@/pages/Settings/Sections/Permissions';
import Teammates from '@/pages/Settings/Sections/Teammates';
import TeamsAndRoles from '@/pages/Settings/Sections/TeamsandRoles';
import SignUp from '@/pages/SignUp';
import TeammateSignUp from '@/pages/TeammateSignUp';
import routeNames from '@/routes/routeNames';
import { observer } from 'mobx-react-lite';
import { Outlet, Route, Routes } from 'react-router-dom';
import Recipients from '@/pages/EmailMarketing/Recipients';
import CampaignCalendar from '@/pages/EmailMarketing/CampaignCalendar';

const AppRoutes = () => {
  return (
    <Routes>
      <Route path={routeNames.landing} element={<LandingPage />} />
      <Route path={routeNames.authentication.login} element={<Login />} />
      <Route path={routeNames.appDownload} element={<Download />} />
      <Route path={routeNames.pwa.loyalty} element={<LoyaltyPWA />} />

      <Route path={routeNames.authentication.signUp} element={<SignUp />} />
      <Route
        path={routeNames.authentication.forgotPassword}
        element={<ForgotPassword />}
      />
      <Route
        path={routeNames.authentication.resetPassword}
        element={<NewPassword />}
      />
      <Route
        path={`${routeNames.dashboard.home}`}
        element={
          <RequireAuth checkEmailVerification>
            <Layout />
          </RequireAuth>
        }
      >
        <Route index element={<Home />} />
        <Route path={routeNames.dashboard.chat} element={<Inbox />}>
          <Route
            index
            element={
              <MainChatUi
                title='Chat'
                conversationChannel='CHAT'
                showLeftPanel={true}
              />
            }
          />
          <Route path='email' element={<MainChatUi title='Email' />} />
          <Route
            path='sms'
            element={
              <MainChatUi
                title='SMS'
                conversationChannel='SMS'
                showLeftPanel={true}
              />
            }
          />
          <Route path='messenger' element={<MainChatUi title='Messenger' />} />
          <Route
            path='whatsapp'
            element={
              <MainChatUi title='Whatsapp' conversationChannel='WHATSAPP' />
            }
          />
        </Route>

        <Route path={routeNames.dashboard.contacts} element={<Outlet />}>
          <Route index element={<Contacts type='all' />} />
          <Route path='my-contacts' element={<Contacts type='my' />} />
          <Route
            path='recently-viewed'
            element={<Contacts type='recently-viewed' />}
          />
          <Route path='list/:listId' element={<Contacts type='list' />} />
          <Route path={'contact-profile/:id'} element={<UserProfile />} />
        </Route>
        <Route path={routeNames.dashboard.loyalty} element={<Loyalty />}>
          <Route path='design' element={<Design />} />
          <Route path='setting' element={<Setting />} />
          <Route path='card-type' element={<CardType />} />
        </Route>
        <Route path={routeNames.dashboard.emailMarketing}>
          <Route index element={<Analytics />} />
          <Route path='campaigns' element={<CampaignManager />} />
          <Route path='templates' element={<Templates />} />
          <Route path='setup' element={<Setup />} />
          <Route path='audience' element={<Recipients />} />
          <Route path='campaign-calendar' element={<CampaignCalendar />} />
        </Route>

        <Route
          path={routeNames.dashboard.configurations}
          element={<Configurations />}
        />
        <Route
          path={routeNames.dashboard.integration}
          element={<Integration />}
        />
        <Route path={routeNames.dashboard.settings} element={<Settings />}>
          <Route index element={<GeneralSettings />} />
          <Route path='teammates' element={<Teammates />} />
          <Route path={`teams&roles`} element={<TeamsAndRoles />} />
          <Route path='office-hours' element={<OfficeHours />} />
          <Route path='blocked-people' element={<BlockedPeople />} />
          <Route
            path={routeNames.dashboard.invites}
            element={<InvitePeople />}
          />
          <Route path='custom-fields' element={<CustomFields />} />
          <Route
            path={routeNames.dashboard.permissions}
            element={<Permissions />}
          />
        </Route>

        <Route
          path={routeNames.dashboard.agentProfile + '/:id'}
          element={<AgentProfile />}
        />
      </Route>

      <Route
        path={routeNames.workspaces.add}
        element={
          <RequireAuth>
            <AddWorkSpace />
          </RequireAuth>
        }
      />
      <Route path={routeNames.teams.sign_up} element={<TeammateSignUp />} />
      <Route
        path={routeNames.emailVerification.verificationPage}
        element={
          <RequireAuth>
            <EmailVerifyPage />
          </RequireAuth>
        }
      />

      <Route
        path={routeNames.emailVerification.verifyEmail}
        element={
          <RequireAuth>
            <EmailVerifyPage />
          </RequireAuth>
        }
      />
      <Route
        path={`${routeNames.teams.join}/:invite_id`}
        element={
          <RequireAuth>
            <JoinTeam />
          </RequireAuth>
        }
      />
      <Route
        path={routeNames.dashboard.emailTemplates}
        element={
          <RequireAuth>
            <EmailTemplatesBuilder />
          </RequireAuth>
        }
      />
      <Route
        path={`${routeNames.dashboard.emailTemplates}/:id`}
        element={
          <RequireAuth>
            <EmailTemplatesBuilder />
          </RequireAuth>
        }
      />
      <Route path='*' element={<NoteFoundPage />} />
    </Routes>
  );
};

export default observer(AppRoutes);
