import Spinner from '@/components/atoms/Spinner';
import Text from '@/components/atoms/Text';
import {
  ChatIcon,
  EmailIcon,
  MessengerIcon,
  SMSIcon,
  WhatsAppIcon,
} from '@/components/molecules/FilterTabs/icons';
import { Tab, TabPanel } from '@/components/molecules/Tab';
import { ConStore } from '@/state/ConversationStore';
import { motion } from 'framer-motion';
import { observer } from 'mobx-react-lite';
import { FC, useEffect, useState } from 'react';
import ChatArea from '../../organisms/ChatArea';
import ConversationList from '../../organisms/ConversationList';
import ConversationProfilePanel from '../../organisms/ConversationProfilePanel';
import NoConversations from '../NoConversations';
import NoConversationSelected from '../NoConversationSelected';
import { User } from '@/types/user.types';
import { useChatConversation } from '@/hooks/useChatConversation';
import { CONVERSATION_SORT, CONVERSATION_STATUS } from '@/constants';

export interface MainChatUiProps {
  title: 'Chat' | 'Email' | 'SMS' | 'Messenger' | 'Whatsapp';
  conversationChannel?: 'CHAT' | 'EMAIL' | 'SMS' | 'MESSENGER' | 'WHATSAPP';
  showLeftPanel?: boolean;
  userData?: User | null;
}

const titles: MainChatUiProps['title'][] = [
  'Chat',
  'Email',
  'SMS',
  'Messenger',
  'Whatsapp',
];
const conversationChannel: MainChatUiProps['conversationChannel'][] = [
  'CHAT',
  'EMAIL',
  'SMS',
  'MESSENGER',
  'WHATSAPP',
];

const MainChatUi: FC<MainChatUiProps> = props => {
  const [title, setTitle] = useState<MainChatUiProps['title']>(
    props.title || 'Chat',
  );
  const [currentIndex, setCurrentIndex] = useState(0);
  const [channelIndex, setChannelIndex] = useState(0);
  const [, setPrevIndex] = useState(0);
  const [, setNextIndex] = useState(2);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [chatCount, setChatCount] = useState(0);
  const [smsCount, setSmsCount] = useState(0);
  const { getConversations } = useChatConversation();

  const navigate = (index: number) => {
    setPrevIndex(currentIndex - 1);
    setNextIndex(currentIndex + 1);
    setCurrentIndex(index);
  };

  const goBack = () => {
    setCurrentIndex(currentIndex - 1);
  };
  const next = () => {
    setCurrentIndex(currentIndex + 1);
  };
  const navigation = {
    navigate,
    goBack,
    next,
  };
  const handleTabChange = (index: number) => {
    const tabTitle = titles[index];
    setTitle(tabTitle);
    setChannelIndex(index);
  };

  useEffect(() => {
    getConversations({
      page: 1,
      status: CONVERSATION_STATUS.OPEN,
      sort: ConStore.sorted_by === CONVERSATION_SORT.LATEST ? -1 : 1,
      channel: conversationChannel[channelIndex],
    });
  }, [conversationChannel[channelIndex]]);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    ConStore.unread_channel_conversations_counts.map(convo => {
      if (convo.name == 'CHAT') {
        setChatCount(convo.count);
      } else if (convo.name == 'SMS') {
        setSmsCount(convo.count);
      }
    });
  }, [
    ConStore.unread_channel_conversations_counts,
    conversationChannel[channelIndex],
  ]);

  useEffect(() => {
    ConStore.set_selected_conversation_id(null);
  }, [props.conversationChannel, conversationChannel[channelIndex]]);

  const routes = [
    {
      path: 'Conversations',
      element: (
        <div>
          <Text size='lg' className='text-black px-4 py-2'>
            Inbox
          </Text>
          <Tab onTabChange={handleTabChange}>
            <TabPanel
              icon={<ChatIcon />}
              isIcon={true}
              title='Chat'
              conversationChannel='CHAT'
              isRibbon={true}
              count={chatCount}
            ></TabPanel>
            <TabPanel
              icon={<EmailIcon />}
              isIcon={true}
              title='Email'
              conversationChannel='EMAIL'
            ></TabPanel>
            <TabPanel
              icon={<SMSIcon />}
              isIcon={true}
              title='SMS'
              conversationChannel='SMS'
              isRibbon={true}
              count={smsCount}
            ></TabPanel>
            <TabPanel
              icon={<MessengerIcon />}
              isIcon={true}
              title='Messenger'
              conversationChannel='MESSENGER'
            ></TabPanel>
            <TabPanel
              icon={<WhatsAppIcon />}
              isIcon={true}
              title='Whatsapp'
              conversationChannel='WHATSAPP'
            ></TabPanel>
          </Tab>
          <div className='border border-x-0 border-t-0 border-border mt-3'></div>
          <ConversationList
            {...props}
            navigation={navigation}
            fullWidth
            title={title}
          />
        </div>
      ),
    },
    {
      path: 'chat',
      element: (
        <div>
          {ConStore.selected_conversation_id !== null ? (
            <div>
              {ConStore.fetchingConversations ||
              ConStore.fetching_closed_conversations ? (
                <div className='flex justify-center items-center h-full w-full'>
                  <Spinner size={40} color='#033EB5' />
                </div>
              ) : (
                <ChatArea navigation={navigation} />
              )}
            </div>
          ) : (
            <NoConversations />
          )}
        </div>
      ),
    },

    {
      path: 'details',
      element: <ConversationProfilePanel navigation={navigation} {...props} />,
    },
  ];

  return (
    <>
      {windowWidth > 425 ? (
        <motion.div
          layout
          className='hidden z-10 md:flex flex-row w-full h-full max-h-[94vh] overflow-hidden  shadow-xl border border-gray-20 bg-[#edeff2]'
        >
          <ConversationList
            {...{
              conversationChannel: conversationChannel[channelIndex],
              ...props,
            }}
          />
          {ConStore.fetchingConversations ? (
            <div className='flex justify-center items-center h-full w-full'>
              <Spinner size={40} color='#033EB5' />
            </div>
          ) : ConStore.conversations.length > 0 ? (
            ConStore.selected_conversation_id ? (
              <>
                <ChatArea />
                {props.showLeftPanel && (
                  <ConversationProfilePanel
                    navigation={navigation}
                    {...props}
                    {...{
                      conversationChannel: conversationChannel[channelIndex],
                    }}
                  />
                )}
              </>
            ) : (
              <NoConversationSelected />
            )
          ) : (
            <NoConversations />
          )}
        </motion.div>
      ) : (
        <motion.div
          layout
          className='md:hidden z-10 w-full h-full  bg-[#ffffff]'
        >
          {routes[currentIndex].element}
        </motion.div>
      )}
    </>
  );
};
export default observer(MainChatUi);
