import Button from '@/components/atoms/Button';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/dialog';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';
import {
  Sheet,
  SheetContent,
  SheetDescription,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from '@/components/ui/sheet';
import { _fields } from '@/pages/Contacts/mobile/menu';
import { customFieldStore } from '@/state/CustomFieldStore';
import { FilterIcon, Plus, X } from 'lucide-react';
import { observer } from 'mobx-react-lite';
import { FC, useMemo, useState } from 'react';

export type FilterCondition = {
  id: string;
  field: string;
  operator: string;
  value: string;
  label: string;
};

const getType = (s: string) => {
  switch (s) {
    case 'last_seen':
    case 'createdAt':
      return 'date';
    case '':
      return 'text';
    default:
      return 'text';
  }
};

const ops = [
  {
    id: 1,
    name: 'Contains',
    value: 'contains',
  },
  {
    id: 2,
    name: 'Equals',
    value: 'equals',
  },
  {
    id: 3,
    name: 'Starts With',
    value: 'starts_with',
  },
  {
    id: 4,
    name: 'Greater Than',
    value: 'greater_than',
  },
  {
    id: 5,
    name: 'Less Than',
    value: 'less_than',
  },
];

export const filterStoreKey = 'contactFilters';

export const Filter: FC = observer(() => {
  const storedFilters = (
    localStorage.getItem(filterStoreKey)
      ? JSON.parse(localStorage.getItem(filterStoreKey) as string)
      : []
  ) as FilterCondition[];
  const [isLoading, setIsLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [filters, setFilters] = useState<FilterCondition[]>(storedFilters);
  const [newFilter, setNewFilter] = useState<Partial<FilterCondition>>({});
  const [allowedOperator, setAllowedOperator] = useState<typeof ops>([]);
  const [selectedField, setSelectedField] = useState<
    (typeof fields)[number] | null
  >(null);

  const lists = useMemo(
    () => [
      { value: 'all', label: 'All Contacts' },
      { value: 'my', label: 'My Contacts' },
    ],
    [],
  );

  const fields = useMemo(
    () => [
      ...(_fields?.map(f => ({
        label: f.name,
        value: f.value,
        type: getType(f.value),
        allowedOps: f.allowed_operators,
      })) ?? []),
      ...(customFieldStore.customFields?.map(cf => ({
        label: cf.field,
        value: cf._id,
        allowedOps: getAllowedOps(cf.type),
        type: cf.type,
      })) ?? []),
    ],
    [customFieldStore?.customFields?.length],
  );

  function getAllowedOps(fieldType: string) {
    switch (fieldType) {
      case '':
        return [];
      default:
        return [1, 2, 3];
    }
  }

  const handleFieldSelected = (allowedOp: number[]) => {
    const foundOps = ops?.filter(op => {
      const original = allowedOp.find(a => a === op.id);
      return !!original;
    });

    setAllowedOperator(foundOps);
  };

  const handleAddFilter = () => {
    if (newFilter.field && newFilter.operator && newFilter.value) {
      setIsLoading(true);
      // Simulate API call
      const filtersToSave = [
        ...filters,
        { ...(newFilter as FilterCondition), id: Math.random().toString() },
      ];
      setTimeout(() => {
        setFilters(filtersToSave);
        localStorage.setItem(filterStoreKey, JSON.stringify(filtersToSave));
        dispatchEvent(
          new StorageEvent('storage', {
            key: filterStoreKey,
            newValue: JSON.stringify(filtersToSave),
            storageArea: localStorage,
          }),
        );
        setNewFilter({});
        setIsOpen(false);
        setIsLoading(false);
      }, 500);
    }
  };

  const handleRemoveFilter = (id: string) => {
    const filtersToRemove = filters.filter(f => f.id !== id);
    setFilters(filtersToRemove);
    localStorage.setItem(filterStoreKey, JSON.stringify(filtersToRemove));
    dispatchEvent(
      new StorageEvent('storage', {
        key: filterStoreKey,
        newValue: JSON.stringify(filtersToRemove),
        oldValue: JSON.stringify(filters),
        storageArea: localStorage,
      }),
    );
  };

  const handleRemoveAll = () => {
    setFilters([]);
    localStorage.setItem(filterStoreKey, JSON.stringify([]));
    dispatchEvent(
      new StorageEvent('storage', {
        key: filterStoreKey,
        newValue: JSON.stringify([]),
        oldValue: JSON.stringify(filters),
        storageArea: localStorage,
      }),
    );
  };

  return (
    <div className='flex h-full flex-col [--primary:theme(colors.blue.600)] [--primary-foreground:theme(colors.white)]'>
      <div className=''>
        <div className='flex h-16 items-center px-4'>
          <Sheet>
            <SheetTrigger asChild>
              <Button
                variant='outline'
                text={'Filters (' + filters.length + ')'}
                LeftIcon={<FilterIcon className='mr-2 h-4 w-4' />}
              />
            </SheetTrigger>
            <SheetContent className='w-[400px]'>
              <SheetHeader>
                <SheetTitle>Filters</SheetTitle>
                <SheetDescription className='text-md'>
                  Filter by Owner
                  <Select defaultValue='all'>
                    <SelectTrigger className='mt-2'>
                      <SelectValue placeholder='Select owner' />
                    </SelectTrigger>
                    <SelectContent>
                      {lists.map(list => (
                        <SelectItem key={list.value} value={list.value}>
                          {list.label}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                </SheetDescription>
              </SheetHeader>

              <div className='mt-6'>
                <div className='text-[14px] text-muted-foreground'>
                  Matching all of these filters
                </div>
                <div className='mt-4 space-y-4'>
                  {filters.map(filter => (
                    <div
                      key={filter.id}
                      className='flex items-center justify-between rounded-lg border p-4'
                    >
                      <div className='space-y-1 text-[14px]'>
                        <div className='font-medium'>{filter.label}</div>
                        <div className='text-muted-foreground'>
                          {filter.operator} {filter.value}
                        </div>
                      </div>
                      <Button
                        variant='outline'
                        className='w-8 h-6'
                        text=''
                        LeftIcon={<X className='h-4 w-4' />}
                        onClick={() => handleRemoveFilter(filter.id)}
                      />
                    </div>
                  ))}
                </div>

                <div className='mt-6 flex flex-col gap-4'>
                  <Dialog open={isOpen} onOpenChange={setIsOpen}>
                    <Button
                      variant='outline'
                      text='Add Filter'
                      LeftIcon={<Plus className='mr-2 h-4 w-4' />}
                      onClick={() => setIsOpen(true)}
                      className='justify-start'
                    />
                    <DialogContent>
                      <DialogHeader>
                        <DialogTitle>Add Filter</DialogTitle>
                      </DialogHeader>
                      <div className='grid gap-4 py-4'>
                        <div className='grid gap-2'>
                          <Label htmlFor='field'>Field</Label>
                          <Select
                            onValueChange={value => {
                              setNewFilter({ ...newFilter, field: value });
                            }}
                          >
                            <SelectTrigger>
                              <SelectValue placeholder='Select field' />
                            </SelectTrigger>
                            <SelectContent>
                              {fields?.map(f => (
                                <SelectItem
                                  key={f.value}
                                  value={f.value}
                                  onMouseEnter={() => {
                                    handleFieldSelected(f.allowedOps);
                                    setSelectedField(f);
                                    setNewFilter({
                                      ...newFilter,
                                      label: f.label,
                                    });
                                  }}
                                >
                                  {f.label}
                                </SelectItem>
                              ))}
                            </SelectContent>
                          </Select>
                        </div>
                        <div className='grid gap-2'>
                          <Label htmlFor='operator'>Operator</Label>
                          <Select
                            disabled={allowedOperator.length == 0}
                            onValueChange={value =>
                              setNewFilter({ ...newFilter, operator: value })
                            }
                          >
                            <SelectTrigger>
                              <SelectValue placeholder='Select operator' />
                            </SelectTrigger>
                            <SelectContent>
                              {allowedOperator?.map(op => (
                                <SelectItem key={op.id} value={op.value}>
                                  {op.name}
                                </SelectItem>
                              ))}
                            </SelectContent>
                          </Select>
                        </div>
                        <div className='grid gap-2'>
                          <Label htmlFor='value'>Value</Label>
                          <Input
                            id='value'
                            type={selectedField?.type ?? 'text'}
                            placeholder='Enter value'
                            onChange={e =>
                              setNewFilter({
                                ...newFilter,
                                value: e.target.value,
                              })
                            }
                          />
                        </div>
                      </div>
                      <Button
                        text='Add Filter'
                        size='md'
                        loading={isLoading}
                        onClick={handleAddFilter}
                        disabled={isLoading}
                      />
                    </DialogContent>
                  </Dialog>

                  {filters.length > 0 && (
                    <>
                      <Button
                        text='Remove All'
                        variant='outline'
                        onClick={handleRemoveAll}
                        className='justify-start text-destructive'
                      />
                      {/* <Button
                        variant='outline'
                        text='Add Filter Logic'
                        textColor='text-blue-600 hover:bg-blue-50 hover:text-blue-700'
                      /> */}
                    </>
                  )}
                </div>
              </div>
            </SheetContent>
          </Sheet>
        </div>
      </div>
    </div>
  );
});
