import Search from '@/components/atoms/Search';
import { useActivityLog } from '@/hooks/useActivityLog';
import { useDebounceValue } from '@/hooks/useDebounce';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import ActivityList from './activityList';

const Activities = () => {
  const { id: contactId } = useParams<{ id: string }>();
  const [search, setSearch] = useState('');
  const { getActivityLogs } = useActivityLog();
  const debouncedSearchValue = useDebounceValue(search);

  useEffect(() => {
    getActivityLogs(contactId, debouncedSearchValue);
  }, [debouncedSearchValue]);

  return (
    <div className='flex flex-col py-4'>
      <div className='flex justify-between mb-4'>
        <div className='relative flex items-center mr-2 sm:w-96 md:w-[500px]'>
          <Search
            placeholder='Search a Activity'
            transparent
            showCommand={false}
            onChange={e => setSearch(e.target.value)}
          />
        </div>
      </div>
      <ActivityList />
    </div>
  );
};
export default Activities;
