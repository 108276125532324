import { Popover } from '@headlessui/react';
import React, { FC, InputHTMLAttributes, useEffect, useMemo } from 'react';
import EmojiPickerModal from '../modal/EmojiPickerModal';
import { emojiList } from '@/constants/index';
import GraphemeSplitter from 'grapheme-splitter';

interface ConfigInputProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  error?: string;
  hint?: string;
  leftIcon?: React.ReactNode;
  showRequired?: boolean;
  value: string;
  onChange: (value: any) => void;
  onSelectEmoji?: (value: string) => void;
}

const ConfigInput: FC<ConfigInputProps> = ({
  showRequired = false,
  label,
  placeholder,
  type,
  error,
  maxLength,
  value,
  onChange,
  onSelectEmoji = () => null,
  ...props
}) => {
  const splitter = new GraphemeSplitter();

  const adjustedValue = useMemo(() => {
    if (typeof value === 'string' && maxLength !== undefined) {
      const graphemes = splitter.splitGraphemes(value);
      return graphemes.length > maxLength
        ? graphemes.slice(0, maxLength).join('')
        : value;
    }
    return value;
  }, [value, maxLength]);

  useEffect(() => {
    if (adjustedValue !== value) {
      onChange({ target: { value: adjustedValue } });
    }
  }, [adjustedValue, value, onChange]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    const graphemes = splitter.splitGraphemes(inputValue);

    const truncatedValue =
      maxLength && graphemes.length > maxLength
        ? graphemes.slice(0, maxLength).join('')
        : inputValue;

    onChange({ target: { value: truncatedValue } });
  };

  return (
    <div className='relative'>
      {label && (
        <div className='flex items-center mb-2 '>
          <label className='block text-gray-400 text-md bold font-medium'>
            {label}
          </label>
          {showRequired && (
            <span className='block text-warning text-md bold font-medium ml-2 mt-1'>
              *
            </span>
          )}
        </div>
      )}
      <div className='w-full flex flex-row items-center h-[40px] border mb-2 rounded-[4px] outline-none  border-gray-300 border-b-[2px] bg-white '>
        <input
          {...props}
          type={type}
          value={adjustedValue}
          onChange={handleInputChange}
          maxLength={maxLength}
          className='placeholder:text-placeholder text-[14px] leading-[20px] h-full bg-inherit flex items-center justify-center font-medium px-[12px] outline-none w-[92%]'
          placeholder={placeholder}
        />
        {label !== 'Outside URL' && (
          <Popover>
            <Popover.Button>
              <div className='group cursor-pointer mt-[10px] ml-2 '>
                <svg
                  className='group-hover:text-secondary text-[#7E8B99]'
                  width='19'
                  height='19'
                  viewBox='0 0 19 19'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    d='M16.7148 0.285156H3.21484C1.94922 0.285156 0.964844 1.26953 0.964844 2.5V12.5898C0.964844 13.8203 1.94922 14.8047 3.21484 14.8047H6.58984V17.7578C6.58984 18.1094 6.97656 18.2852 7.25781 18.0742L11.6523 14.8047H16.7148C17.9453 14.8047 18.9648 13.7852 18.9648 12.5898V2.5C18.9648 1.26953 17.9805 0.285156 16.7148 0.285156ZM17.3125 12.625C17.3125 12.9414 17.0312 13.1875 16.75 13.1875H11.125L8.3125 15.2969V13.1875H3.25C2.93359 13.1875 2.6875 12.9414 2.6875 12.625V2.5C2.6875 2.21875 2.93359 1.9375 3.25 1.9375H16.75C17.0312 1.9375 17.3125 2.21875 17.3125 2.5V12.625ZM12.25 6.4375C12.8477 6.4375 13.375 5.91016 13.375 5.3125C13.375 4.75 12.8477 4.1875 12.25 4.1875C11.6875 4.1875 11.1602 4.71484 11.1602 5.3125C11.1602 5.94531 11.6172 6.4375 12.25 6.4375ZM7.75 6.4375C8.34766 6.4375 8.875 5.91016 8.875 5.3125C8.875 4.75 8.34766 4.1875 7.75 4.1875C7.1875 4.1875 6.66016 4.71484 6.66016 5.3125C6.66016 5.94531 7.11719 6.4375 7.75 6.4375ZM12.4258 8.72266C11.8281 9.42578 10.9492 9.8125 10 9.8125C9.01562 9.8125 8.13672 9.42578 7.53906 8.72266C7.22266 8.37109 6.69531 8.30078 6.34375 8.61719C5.99219 8.93359 5.95703 9.46094 6.23828 9.8125C7.1875 10.9023 8.52344 11.5352 10 11.5352C11.4414 11.5352 12.7773 10.9023 13.7266 9.8125C14.0078 9.46094 13.9727 8.93359 13.6211 8.61719C13.2695 8.30078 12.7422 8.37109 12.4258 8.72266Z'
                    fill='currentColor'
                  />
                </svg>
              </div>
            </Popover.Button>
            <Popover.Panel>
              <div className='w-[364px] flex items-center flex-col rounded-lg h-[250px] top-[30px] left-[180px] border-[#E6E6E6] border bg-white absolute bottom-2 z-10 overflow-y-auto'>
                <EmojiPickerModal
                  emojiList={emojiList}
                  handleEmojiClick={onSelectEmoji}
                  {...props}
                />
              </div>
            </Popover.Panel>
          </Popover>
        )}
      </div>
      {error && <small className='text-warning text-sm'>{error}</small>}
    </div>
  );
};
export default ConfigInput;
