import React, { useState, FC } from 'react';
import { observer } from 'mobx-react-lite';
import { RiArrowDropDownLine } from 'react-icons/ri';

export interface FilterOption {
  id: number;
  title: string;
  iconRight?: React.ReactNode;
  type?: 'button' | 'tab';
  disabled?: boolean;
  mt?: number;
}

interface DropdownMenuProps {
  options: FilterOption[];
  icon?: React.ReactNode;
  activeTab: number;
  mt?: number;
  currentOption?: string;
  onChange: (id: number) => void;
  handleButtonClick?: () => void;
}

const Dropdown: FC<DropdownMenuProps> = ({
  options,
  icon,
  currentOption,
  onChange,
}) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleOptionClick = (option: FilterOption) => {
    setIsDropdownOpen(false);
    onChange(option.id);
  };

  const currentValue = currentOption ? currentOption.replace(/_/g, ' ') : '';

  return (
    <div className='relative z-50'>
      <div className='flex items-center'>
        <button
          onClick={toggleDropdown}
          className='flex justify-between px-2 items-center  text-gray-400 text-[13px]  bg-[#F8F8F8] min-w-[136px] h-9 rounded-[5px]'
        >
          {icon}
          <span>{currentValue}</span>
          <RiArrowDropDownLine className='w-6 h-6' />
        </button>
      </div>
      {isDropdownOpen && (
        <div className='absolute left-1 min-w-[136px] bg-white shadow-lg  border border-gray-200'>
          {options.map(option => (
            <div
              key={option.id}
              onClick={() => !option.disabled && handleOptionClick(option)}
              className={`${
                option.disabled
                  ? 'cursor-not-allowed text-gray-400'
                  : 'cursor-pointer text-gray-800'
              } py-2 px-1 hover:bg-gray-100 text-[13px]`}
            >
              {option.title}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
export default observer(Dropdown);
